import {ChangeDetectionStrategy, Component, inject, Input} from "@angular/core";
import {AdminMenuSection} from "../../../../../core/models/admin-menu-item.model";
import {AuthenticationService} from '../../../../../core/services/authentication.service';
import {Member} from "../../../../../core/models/member.model";
import {Router} from '@angular/router';

@Component({
  selector: 'app-admin-menu-desktop',
  templateUrl: './admin-menu-desktop.component.html',
  styleUrls: ['./admin-menu-desktop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminMenuDesktopComponent {

  readonly #authentService = inject(AuthenticationService)
  readonly #router = inject(Router);
  private _memberConnected: Member | null = null;

  private _adminMenuSections: AdminMenuSection[] = [
    {
      roles:['ROLE_CLIENT_ADMIN', 'ROLE_ADMIN', 'ROLE_INSTALLER'],
      items: [
        {
          path: 'dashboard',
          name: 'Tableau de bord',
          inactiveIcon: 'assets/images/icons/dashboard.svg',
          activeIcon: 'assets/images/icons/orange/dashboard.svg'
        },
      ]
    },
    {
      title: 'Gestion du parc matériel',
      roles:['ROLE_CLIENT_ADMIN', 'ROLE_ADMIN', 'ROLE_INSTALLER'],
      items: [
        {
          path: 'orders',
          name: 'Commandes',
          inactiveIcon: 'assets/images/icons/order.svg',
          activeIcon: 'assets/images/icons/orange/order.svg'
        },
        {
          path: 'installations',
          name: 'Installations en attente',
          inactiveIcon: 'assets/images/icons/installation.svg',
          activeIcon: 'assets/images/icons/orange/installation.svg'
        },
        {
          path: 'hardwares',
          name: 'Matériels',
          inactiveIcon: 'assets/images/icons/hardware.svg',
          activeIcon: 'assets/images/icons/orange/hardware.svg'
        },
      ]
    },
    {
      title: 'Gestion des espaces',
      roles:['ROLE_CLIENT_ADMIN', 'ROLE_ADMIN'],
      items: [
        {
          path: 'customers',
          name: 'Particuliers',
          inactiveIcon: 'assets/images/icons/customer.svg',
          activeIcon: 'assets/images/icons/orange/customer.svg'
        },
        {
          path: 'associations',
          name: 'Associations',
          inactiveIcon: 'assets/images/icons/association.svg',
          activeIcon: 'assets/images/icons/orange/association.svg'
        },
      ]
    },
    {
      title: 'Gestion des données administrables',
      roles:['ROLE_CLIENT_ADMIN', 'ROLE_ADMIN'],
      items: [
        {
          path: 'admins',
          name: 'Utilisateurs du BO',
          inactiveIcon: 'assets/images/icons/admin.svg',
          activeIcon: 'assets/images/icons/orange/admin.svg'
        },
        {
          path: 'subscriptions',
          name: 'Abonnements',
          inactiveIcon: 'assets/images/icons/subscription.svg',
          activeIcon: 'assets/images/icons/orange/subscription.svg'
        },
        {
          path: 'options',
          name: 'Options d’abonnement',
          inactiveIcon: 'assets/images/icons/option.svg',
          activeIcon: 'assets/images/icons/orange/option.svg'
        },
        {
          path: 'faq',
          name: 'FAQ',
          inactiveIcon: 'assets/images/icons/faq.svg',
          activeIcon: 'assets/images/icons/orange/faq.svg'
        },
        {
          path: 'tips',
          name: 'Astuces',
          inactiveIcon: 'assets/images/icons/tips.svg',
          activeIcon: 'assets/images/icons/orange/tips.svg'
        },
        {
          path: 'guides',
          name: 'Guides et tutoriels',
          inactiveIcon: 'assets/images/icons/guide-tutorial.svg',
          activeIcon: 'assets/images/icons/orange/guide-tutorial.svg'
        },
      ]
    },
    {
      title: 'Gestion des documents légaux',
      roles:['ROLE_CLIENT_ADMIN', 'ROLE_ADMIN'],
      items: [
        {
          path: 'notice',
          name: 'Mentions légales',
          inactiveIcon: 'assets/images/icons/legal-information.svg',
          activeIcon: 'assets/images/icons/orange/legal-information.svg'
        },
        {
          path: 'privacy-policy',
          name: 'Politique de confidentialité',
          inactiveIcon: 'assets/images/icons/privacy-policy.svg',
          activeIcon: 'assets/images/icons/orange/privacy-policy.svg'
        },
      ]
    }
  ]


  get memberConnected(): Member | null {
    return this._memberConnected;
  }

  @Input({required: true})
  set memberConnected(value: Member | null) {
    this._memberConnected = value;
  }

  get adminMenuSections(): AdminMenuSection[] {
    return this._adminMenuSections;
  }

  public getInitials(firstname: string, lastname: string){
    return firstname.charAt(0).toUpperCase() + lastname.charAt(0).toUpperCase();
  }

  logout() {
    this.#authentService.logout();
    this.#router.navigate(['/']);
  }
}

import {CanActivateFn, Router} from "@angular/router";
import {inject} from "@angular/core";
import {AuthenticationService} from "../services/authentication.service";

export function isAdminGuard(): CanActivateFn {
  return (route, state) => {
    const authenticationService = inject(AuthenticationService);
    const router = inject(Router);

    if (!authenticationService.isAdmin) {
      return router.createUrlTree(['/']);
    }

    return true;
  };
}

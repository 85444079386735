import {CanActivateFn, Router} from "@angular/router";
import {inject} from "@angular/core";
import {AuthenticationService} from "../services/authentication.service";

export function isAuthenticatedGuard(): CanActivateFn {
  return () => {
    const authenticationService = inject(AuthenticationService);
    const router = inject(Router);

    if (!authenticationService.isAuthenticated) {
      authenticationService.logout();

      return router.createUrlTree(['/']);
    }

    return true;
  };
}

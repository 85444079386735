import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {concatMap, map, Observable, tap} from 'rxjs';
import {MemberService} from './connected/member.service';
import {Member} from '../models/member.model';
import {RolesEnum} from '../models/roles.enum';
import {ApiRoutesService} from './api-routes.service';
import {JwtResponseModel} from '../models/jwt-response.model';
import {STORAGE_CONSTANTS} from '../utils/storage.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  readonly #httpClient = inject(HttpClient);
  readonly #membersService = inject(MemberService);
  readonly #routes = inject(ApiRoutesService);
  #member: Member | null = null;

  authenticate(login: string, password: string): Observable<boolean> {
    return this.#httpClient.post<JwtResponseModel>(this.#routes.login(), {login, password})
      .pipe(
        // extract data from first call
        tap((token) => {
          this.handleToken(token);
        }),
        // chain member call for authent required data
        concatMap((token) => this.#membersService.me().pipe(
          tap((member) => {
            this.#member = member;
          }),
          // remap token for final result
          map(() => token)
        )),
        map((token) => token != null)
      );
  }

  authenticateCodeValidation(token: JwtResponseModel) {
    this.handleToken(token)
  }

  private handleToken(token: JwtResponseModel): void {
    localStorage.setItem(STORAGE_CONSTANTS.tokenName, JSON.stringify(token));
  }

  public get token(): JwtResponseModel | null {
    const token = localStorage.getItem(STORAGE_CONSTANTS.tokenName);
    if (token) {
      return JSON.parse(token)
    } else {
      return null;
    }
  }

  public get accessToken() {
    return this.token ? this.token.accessToken : null;
  }

  public get refreshToken() {
    return this.token ? this.token.refreshToken : null;
  }

  public get roles(): string[] | [] {
    return this.token ? this.token.roles : [];
  }

  public get isAuthenticated(): boolean {
    return !!this.token;
  }

  public get isMember(): boolean {
    return this.roles.find((role: string) => role === RolesEnum.ROLE_USER.valueOf()) != null;
  }

  public get isInstaller(): boolean {
    return this.roles.find((role: string) => role === RolesEnum.ROLE_INSTALLER.valueOf()) != null;
  }

  public get isAdmin(): boolean {
    return this.roles.find((role: string) => role === RolesEnum.ROLE_CLIENT_ADMIN || role === RolesEnum.ROLE_ADMIN) != null;
  }

  public get member(): Member | null {
    return this.#member;
  }

  logout() {
    localStorage.clear();
    this.#member = null;
  }

  public initializeApp(): Observable<any> {
    return this.#membersService.me().pipe(
      tap((member) => {
        this.#member = member;
      })
    );
  }
}

import {CanActivateFn, Router} from "@angular/router";
import {inject} from "@angular/core";
import {AuthenticationService} from "../services/authentication.service";

export function hasRoleGuard(roles: string[]): CanActivateFn {
  return (route, state) => {
    const authenticationService = inject(AuthenticationService);
    const router = inject(Router);
    const userRoles: string[] = authenticationService.roles;

    const hasRole = userRoles.some(userRole => roles.includes(userRole));

    if (hasRole) {
      return true;
    } else {
      return router.createUrlTree(['/']);
    }
  };
}
